import { createRoot } from 'react-dom/client'
import * as React from 'react'

import getGraphqlForClient from './get-graphql-for-client'
import { IntlProvider, loadTranslations } from './localization/i18n'
import computeFontSizeInPixels from './utils/compute-font-size-in-px'

await (async function () {
  // Support Test
  const supportsContainerQueries = 'container' in document.documentElement.style

  // Conditional Import
  if (!supportsContainerQueries) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line import/no-extraneous-dependencies
    await import('shadow-container-query-polyfill')
  }

  const CONTAINER_IDENTIFIER = 'data-eversports-widget-id'

  // Find the container element
  const anchors = document.querySelectorAll(`[${CONTAINER_IDENTIFIER}]`)

  if (!anchors?.length) {
    console.error(
      'Widget script included, but could not find a valid container element for the widget. Please add a valid container element with the data-eversports-widget attribute.',
    )
  } else {
    const loadWidget = async (widgetId: string) => {
      const { widget } = await getGraphqlForClient().LoaderWidget({ widgetId })

      switch (widget.__typename) {
        case 'WidgetActivitySchedule': {
          const { default: module, inlineStyle } = (await import('./widgets/activity-schedule/index')) as Awaited<
            typeof import('./widgets/activity-schedule/index')
          > & { inlineStyle?: string }
          return [React.createElement(module, widget), inlineStyle] as const
        }
        case 'WidgetPrices': {
          const { default: module, inlineStyle } = (await import('./widgets/prices/index')) as Awaited<
            typeof import('./widgets/prices/index')
          > & { inlineStyle?: string }
          return [React.createElement(module, widget), inlineStyle] as const
        }
        case 'WidgetActivityGroups': {
          const { default: module, inlineStyle } = (await import('./widgets/activities/index')) as Awaited<
            typeof import('./widgets/activities/index')
          > & { inlineStyle?: string }
          return [React.createElement(module, widget), inlineStyle] as const
        }
        default:
          throw new Error(`Invalid type ${widget as string}`)
      }
    }

    const locale = navigator.language
    const translationsPromise = loadTranslations(locale)

    await Promise.all(
      Array.from(anchors).map(async (anchor) => {
        // read widget data
        const widgetId = anchor.getAttribute(CONTAINER_IDENTIFIER)
        if (!widgetId) {
          throw new Error(`${CONTAINER_IDENTIFIER} is not defined`)
        }

        // create container element
        const container = document.createElement('div')
        container.style.height = '100%'

        // CSS considers divs containing only shadow roots as empty.
        // Some website builders (shopify) use a global css rule "div:empty { display: none }" which hides the widget.
        // See https://github.com/w3c/csswg-drafts/issues/6771
        // We therefore explicitly set it to display: block
        container.style.display = 'block'

        // determine the base font-size in pixels - it should be a minimum of "16px"
        const computedBaseFontSizeInPixels = computeFontSizeInPixels()

        // create shadow dom
        const shadowRoot = container.attachShadow({ mode: 'open' })

        // container as react root
        anchor.append(container)

        // render react element inside the container
        const root = createRoot(shadowRoot)

        const [[ReactNode, inlineStyle], translations] = await Promise.all([loadWidget(widgetId), translationsPromise])

        // create style sheet
        const style = document.createElement('style')

        // Set a css variable for the base font sizing - add to any other inlineStyle
        style.innerText = `:host { --eversports-widget-font-size-px: ${computedBaseFontSizeInPixels}; } ${
          inlineStyle || ''
        } `

        // Appened that and any inline styles to the shadow dom
        shadowRoot.append(style)

        root.render(
          <IntlProvider translations={translations} locale={locale}>
            {ReactNode}
          </IntlProvider>,
        )
      }),
    )
    console.info('Eversports widget setup complete')
  }
})()
